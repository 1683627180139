import React, {FC} from "react";
import {StyledChristmasVoucherRegulamin} from "./ChristmasVoucherRegulamin.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";

export const ChristmasVoucherRegulamin: FC = () => {

    const onCopy = (e: any) => {
        e.preventDefault();
    }

    return <StyledChristmasVoucherRegulamin onCopy={(e) => onCopy(e)}>
        <Title title={"Regulamin Voucherów Świątecznych"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        <div className="c10 c11 doc-content">
            <p className="c3"><span className="c5">I. Informacje wst&#281;pne.</span></p>
            <p className="c1"><span className="c0">Niniejszy Regulamin okre&#347;la og&oacute;lne warunki wydawania, zasady oraz spos&oacute;b korzystania z Bon&oacute;w Podarunkowych, kt&oacute;re pozyskane zosta&#322;y w zabawie &bdquo;Znajd&#378; sw&oacute;j voucher od Miko&#322;aja&rdquo; w dniu 06.12.2024.</span>
            </p>
            <p className="c3"><span className="c6">II. Definicje.</span><span className="c0">&nbsp;</span></p>
            <p className="c1"><span className="c4">1. Bon Podarunkowy &nbsp;&ndash; bon wydany na okaziciela, voucher, wystawiony przez Studio Le&#347;ny Szept, uprawniaj&#261;cy Posiadacza do dokonywania p&#322;atno&#347;ci za oferowane us&#322;ugi w Studiu Le&#347;ny Szept.</span>
            </p>
            <p className="c1"><span className="c0">2. Nabywca &ndash; osoba fizyczna, kt&oacute;ra odnalaz&#322;a Bon Podarunkowy </span>
            </p>
            <p className="c1"><span className="c0">3. Posiadacz &ndash; osoba realizuj&#261;ca Bon Podarunkowy;</span>
            </p>
            <p className="c1"><span className="c4">4. Regulamin &ndash; oznacza niniejszy Regulamin;</span></p>
            <p className="c1"><span
                className="c0">6. Le&#347;ny Szept &ndash; studio tatua&#380;u i piercingu, w kt&oacute;rym oferowane s&#261; do sprzeda&#380;y Us&#322;ugi i Bony Podarunkowe. Studio mie&#347;ci si&#281; przy ul. Brackiej 23/46 w Warszawie.</span>
            </p>
            <p className="c1"><span className="c4 c10">7. Us&#322;uga &ndash; us&#322;uga wykonania tatua&#380;u lub piercingu w studiu Le&#347;ny Szept;</span>
            </p>
            <p className="c1"><span className="c4">8. Wydawca &ndash; oznacza </span><span className="c8">Le&#347;ny Szept Tattoo &amp; Piercing sp. z o.o z siedzib&#261; w Warszawie, wpisan&#261; do rejestru przedsi&#281;biorc&oacute;w prowadzonego przez S&#261;d Rejonowy dla m. st. Warszawy w Warszawie, XIV Wydzia&#322; Gospodarczy Krajowego Rejestru S&#261;dowego pod nr KRS 0000987892</span>
            </p>
            <p className="c1"><span
                className="c8">9. Zabawa - wydarzenie organizowane przez Studio Le&#347;ny Szept, w kt&oacute;rym mo&#380;na wygra&#263; vouchery w wysoko&#347;ci 500, 200, 100 i 50z&#322; na us&#322;ugi w Studiu Le&#347;ny Szept.</span>
            </p>
            <p className="c3"><span className="c5">III. Warunki og&oacute;lne.</span></p>
            <p className="c1"><span className="c0">1. By wzi&#261;&#263; udzia&#322; w Zabawie nale&#380;y: </span></p>
            <ul className="c9 lst-kix_itl0b3z6w1mz-0 start">
                <li className="c1 c2 li-bullet-0"><span className="c0">zaobserwowa&#263; i udost&#281;pni&#263; na swojej relacji jedn&#261; z rolek z profilu studia na instagram, dotycz&#261;cych zabawy,</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span className="c0">W dniu 06.12 obserwowa&#263; relacje na instagramie Le&#347;ny Szept - tam pojawi&#261; sie&#808; wskaz&oacute;wki, gdzie szuka&#263; voucher&oacute;w,</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span
                    className="c0">Po znalezieniu vouchera - nale&#380;y napisa&#263; bezpo&#347;rednio W wiadomo&#347;ci prywatnej na instagram studia, by zg&#322;osi&#263;, &#380;e zosta&#322; on odnaleziony,</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span
                    className="c0">Je&#347;li kt&oacute;rykolwiek z podpunkt&oacute;w wy&#380;ej nie zostanie wykonany - voucher nie zostanie aktywowany.</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span className="c0">Jeden Bon Podarunkowy przypada na jednego Posiadacza. Dlatego prosimy by nie zabiera&#263; wi&#281;cej ni&#380; 1 Bonu Podarunkowego.</span>
                </li>
            </ul>
            <p className="c1"><span
                className="c0">2. W Zabawie mo&#380;na wygra&#263; vouchery o warto&#347;ci 500zl, 200z&#322;, 100z&#322; i 50z&#322;</span>
            </p>
            <p className="c1"><span
                className="c0">3. Bony podarunkowe wa&#380;ne s&#261; do 31.03.2025, co znaczy &#380;e do dnia 31.05.2025 trzeba odby&#263; wizyt&#281; w studiu, a nie tylko zapisa&#263; sie&#808; na wizyt&#281;.</span>
            </p>
            <p className="c1"><span className="c0">4. Osoba kt&oacute;ra odnalaz&#322;a &nbsp;Bon Podarunkowy (Nabywca), nie musi by&#263; jego p&oacute;&#378;niejszym Posiadaczem, co za tym idzie - Bon Podarunkowy mo&#380;na przekaza&#263; dowolnie wybranej przez Nabywc&#281; osobie.</span>
            </p>
            <p className="c1"><span
                className="c0">5. Bon Podarunkowy mo&#380;e by&#263; wykorzystany wy&#322;&#261;cznie do nabycia us&#322;ug w studiu Le&#347;ny Szept.</span>
            </p>
            <p className="c1"><span
                className="c0">6. Bon Podarunkowy nie podlega wymianie na &#347;rodki pieni&#281;&#380;ne (w ca&#322;o&#347;ci lub w cz&#281;&#347;ci) oraz nie podlega zwrotowi.</span>
            </p>
            <p className="c1"><span
                className="c0">7. Posiadacz umawiaj&#261;c sie&#808; na wizyt&#281;, na kt&oacute;rej chce zrealizowa&#263; Bon Podarunkowy ma obowi&#261;zek poinformowa&#263; o tym osob&#281;, do kt&oacute;rej sie&#808; zapisuje w pierwszej wiadomo&#347;ci. Nie ma mo&#380;liwo&#347;ci by wykorzysta&#263; voucher na miejscu, bez wcze&#347;niejszej informacji o jego posiadaniu. Bon Podarunkowy mo&#380;na wykorzysta&#263; na us&#322;ugi od dnia 06.12.2024, wi&#281;c nie obejmuje on ju&#380; zapisanych wizyt, za kt&oacute;re wp&#322;acony zosta&#322; zadatek na konto studia.</span>
            </p>
            <p className="c1"><span className="c0">8. Limity wykorzystania Bon&oacute;w Podarunkowych:</span></p>
            <ul className="c9 lst-kix_23gmwemaue4b-0 start">
                <li className="c1 c2 li-bullet-0"><span className="c0">Bon Podarunkowy o warto&#347;ci 500zl mo&#380;na wykorzysta&#263; tylko i wy&#322;&#261;cznie, gdy cena pocz&#261;tkowa us&#322;ugi tatua&#380;u wynosi minimum 1000z&#322;, a us&#322;ugi piercingu 800z&#322;.</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span
                    className="c0">Bon Podarunkowy o warto&#347;ci &nbsp;200 z&#322; mo&#380;na wykorzysta&#263; tylko i wy&#322;&#261;cznie, gdy cena pocz&#261;tkowa us&#322;ugi tatua&#380;u wynosi minimum 800z&#322;, a us&#322;ugi piercingu 500z&#322;.</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span
                    className="c0">Bon Podarunkowy o warto&#347;ci &nbsp;100z&#322; mo&#380;na wykorzysta&#263; tylko i wy&#322;&#261;cznie, gdy cena pocz&#261;tkowa us&#322;ugi tatua&#380;u wynosi minimum 400z&#322;, a us&#322;ugi piercingu 250z&#322;,</span>
                </li>
                <li className="c1 c2 li-bullet-0"><span
                    className="c0">Bon Podarunkowy o warto&#347;ci &nbsp;50z&#322; mo&#380;na wykorzysta&#263; tylko i wy&#322;&#261;cznie, gdy cena pocz&#261;tkowa us&#322;ugi tatua&#380;u wynosi minimum 300z&#322;, a us&#322;ugi piercingu 150z&#322;.</span>
                </li>
            </ul>
            <p className="c1"><span
                className="c0">9. Bon Podarunkowy mo&#380;e by&#263; wykorzystany do op&#322;acenia cz&#281;&#347;ci us&#322;ugi wykonywanej przez dowoln&#261; osob&#281; pracuj&#261;c&#261; w studiu Le&#347;ny Szept.</span>
            </p>
            <p className="c3"><span className="c0">10. Bon Podarunkowy jest imienny i widnieje na nim imi&#281; i nazwisko Posiadacza, kt&oacute;re ma obowi&#261;zek wpisa&#263; po otrzymaniu Bonu Podarunkowego.</span>
            </p>
            <p className="c3"><span
                className="c0">11. Bon Podarunkowy jest jednorazowego u&#380;ytku, nie mo&#380;na u&#380;y&#263; go ponownie, b&#261;d&#378; roz&#322;o&#380;y&#263; kwoty na mniejsze wizyty.</span>
            </p>
            <p className="c3"><span
                className="c0">12. Promocje i Bony Podarunkowe nie &#322;&#261;cz&#261; sie&#808; ze sob&#261;.</span>
            </p>
            <p className="c3"><span className="c5">IV. Realizacja Bonu Podarunkowego</span></p>
            <p className="c1"><span
                className="c0">1. Posiadacz Bonu Podarunkowego ma prawo do jego realizacji na nast&#281;puj&#261;cych zasadach:</span>
            </p>
            <p className="c1"><span
                className="c0">a. Bon Podarunkowy stanowi form&#281; zap&#322;aty za cz&#281;&#347;&#263; us&#322;ugi wykonywanj w Studiu Le&#347;ny Szept</span>
            </p>
            <p className="c1"><span className="c0">b. Zap&#322;ata za us&#322;ugi wymaga okazania oryginalnego Bonu Podarunkowego w dniu wykonania us&#322;ugi</span>
            </p>
            <p className="c1"><span
                className="c0">c. Posiadacz Bonu Podarunkowego mo&#380;e wykorzysta&#263; kwot&#281; wskazan&#261; na Bonie Podarunkowym na us&#322;ugi dost&#281;pne w ofercie Studia Le&#347;ny Szept dost&#281;pne na stronie internetowej: https://lesnyszept.com/pricing</span>
            </p>
            <p className="c1"><span
                className="c0">d. W przypadku zakupu us&#322;ugi o warto&#347;ci ni&#380;szej ni&#380; warto&#347;&#263; Bonu Podarunkowego, Posiadaczowi nie przys&#322;uguje prawo otrzymania zwrotu pozosta&#322;ej, niewykorzystanej kwoty;</span>
            </p>
            <p className="c1"><span
                className="c0">e. W przypadku zakupu us&#322;ugi o warto&#347;ci wy&#380;szej ni&#380; warto&#347;&#263; Bonu Podarunkowego, Posiadacz zobowi&#261;zany jest dop&#322;aci&#263; r&oacute;&#380;nic&#281;;</span>
            </p>
            <p className="c1"><span
                className="c0">f. Bon posiada termin wa&#380;no&#347;ci, po up&#322;ywie kt&oacute;rego traci wa&#380;no&#347;&#263;. Termin wa&#380;no&#347;ci Bonu Podarunkowego nie ulega zmianie. Po up&#322;ywie terminu wa&#380;no&#347;ci Bonu Podarunkowego, nie mo&#380;na nim dokonywa&#263; p&#322;atno&#347;ci;</span>
            </p>
            <p className="c1"><span className="c0">g. Wydawca nie odpowiada za zgubienie lub uszkodzenie Bonu Podarunkowego przez Nabywc&#281; lub Posiadacza. W takich wypadkach duplikaty nie b&#281;d&#261; wydawane;</span>
            </p>
            <p className="c1"><span className="c0">h. Wydawca zastrzega sobie prawo odmowy op&#322;acenia us&#322;ugi Bonem Podarunkowym, kt&oacute;ry jest nieczytelny, zniszczony, b&#261;d&#378; w inny spos&oacute;b nasuwaj&#261; w&#261;tpliwo&#347;ci co do ich autentyczno&#347;ci. W takim przypadku Bon Podarunkowy uznawany jest za niewa&#380;ny, bez prawa do otrzymania Bonu Podarunkowego zast&#281;pczego;</span>
            </p>
            <p className="c1"><span className="c0">i. Bon Podarunkowy traktowany jest jako zadatek i obowi&#261;zuje go regulamin studia Le&#347;ny Szept; je&#347;li wizyta zostanie odwo&#322;ana, b&#261;d&#378; Klient nie pojawi sie&#808; na wizycie - Bon Podarunkowy jest niewa&#380;ny.</span>
            </p>
            <p className="c1"><span className="c4">j. Wykorzystanie Bonu Podarunkowego przez Posiadacza wymaga akceptacji regulaminu studia Le&#347;ny Szept. W przypadku braku akceptacji regulaminu studia Le&#347;ny Szept nie przys&#322;uguje prawo otrzymania zwrotu, niewykorzystanej kwoty;</span>
            </p>
            <p className="c3"><span className="c5">V. Postanowienia ko&#324;cowe</span></p>
            <p className="c1"><span className="c0">1. Nabywca jest zobowi&#261;zany do poinformowania Posiadacza o warunkach realizacji Bonu Podarunkowego i przekazaniu mu regulaminu studia Le&#347;ny Szept.</span>
            </p>
            <p className="c1"><span className="c0">2. Zakup Bonu Podarunkowego przez Nabywc&#281; oraz jego przedstawienie do realizacji przez Posiadacza jest r&oacute;wnoznaczne z akceptacj&#261; niniejszego Regulaminu przez Nabywc&#281; i Posiadacza.</span>
            </p>
            <p className="c1"><span
                className="c0">3. Tre&#347;&#263; niniejszego Regulaminu jest dost&#281;pna w studiu Le&#347;ny Szept.</span>
            </p>
            <p className="c1"><span
                className="c0">4. W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie maj&#261; przepisy Kodeksu cywilnego i innych ustaw.</span>
            </p>
            <p className="c1"><span
                className="c0">5. S&#261;dem w&#322;a&#347;ciwym do rozstrzygania spor&oacute;w mog&#261;cych wynikn&#261;&#263; na tle stosowania niniejszego Regulaminu jest s&#261;d powszechny w&#322;a&#347;ciwy dla siedziby Wydawcy.</span>
            </p>
            <p className="c1"><span className="c4">6. Wydawca zastrzega sobie prawo zmiany Regulaminu w ka&#380;dym czasie. Wszelkie zmiany obowi&#261;zuj&#261; od daty ich wprowadzenia przez Wydawc&#281;, po udost&#281;pnieniu zmienionego Regulaminu do wgl&#261;du w studiu Le&#347;ny Szept i dotyczy&#263; b&#281;d&#261; Bon&oacute;w Podarunkowego zakupionych po wej&#347;ciu w &#380;ycie zmian Regulaminu.</span>
            </p></div>
    </StyledChristmasVoucherRegulamin>
};
