import {
    getArtistsLink,
    getBookingsLink,
    getChristmasRegulaminLink,
    getHomePageLink,
    getPricingLink,
    getRegulaminLink
} from "./Links.service";
import {MenuRoutes} from "./Menu.type";

export const menuRoutes: MenuRoutes[] = [
    {link: getHomePageLink(), label: "MAIN_PAGE", pathName: "index"},
    {link: getArtistsLink(), label: "ARTISTS", pathName: "artists"},
    // {link: getTattoosLink(), label: "TATTOOS", pathName: "tattoos"},
    // {link: getPiercingLink(), label: "PIERCING", pathName: "piercing"},
    {link: getPricingLink(), label: "PRICING", pathName: "pricing"},
    {link: getBookingsLink(), label: "BOOKINGS", pathName: "bookings"},
    {link: getRegulaminLink(), label: "REGULAMIN", pathName: "regulamin"},
    {link: getChristmasRegulaminLink(), label: "CHRISTMAS_VOUCHER_REGULAMIN", pathName: "regulamin-voucherow-swiatecznych"},
];
