import {Artist} from "./Artists.type";
import {faSquareFacebook, faSquareInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {getKey} from "../../../images/ImageKeyGenerator.service";

// @ts-ignore
import ZOYA_PROFILE from "../../../images/zoya/tatuazystka zoya.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_1 from "../../../images/zoya/tatuaz cma z kwiatami na klatce.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_2 from "../../../images/zoya/tatuaz cmy na brzuchu.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_3 from "../../../images/zoya/tatuaz czaszka z grzybami.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_4 from "../../../images/zoya/tatuaz katedra na plecach.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_5 from "../../../images/zoya/tatuaz krolikow z rogami.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_6 from "../../../images/zoya/tatuaz kruka.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_7 from "../../../images/zoya/tatuaz miecz na klatce.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_8 from "../../../images/zoya/tatuaz motyl na klatce.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_9 from "../../../images/zoya/tatuaz noga lalki.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_10 from "../../../images/zoya/tatuaz oka ze skrzydalmi.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_11 from "../../../images/zoya/tatuaz osmiornicy.webp";
// @ts-ignore
import ZOYA_PORTFOLIO_12 from "../../../images/zoya/tatuaz smoka.webp";

const portfolio: ImageProps[] = [
    {key: getKey(), image: ZOYA_PORTFOLIO_1, alt: "ZOYA_PORTFOLIO_1_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_2, alt: "ZOYA_PORTFOLIO_2_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_3, alt: "ZOYA_PORTFOLIO_3_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_4, alt: "ZOYA_PORTFOLIO_4_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_5, alt: "ZOYA_PORTFOLIO_5_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_6, alt: "ZOYA_PORTFOLIO_6_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_7, alt: "ZOYA_PORTFOLIO_7_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_8, alt: "ZOYA_PORTFOLIO_8_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_9, alt: "ZOYA_PORTFOLIO_9_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_10, alt: "ZOYA_PORTFOLIO_10_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_11, alt: "ZOYA_PORTFOLIO_11_ALT"},
    {key: getKey(), image: ZOYA_PORTFOLIO_12, alt: "ZOYA_PORTFOLIO_12_ALT"},

]

export const Zoya: Artist = {
    name: "ZOYA_NAME",
    description: "ZOYA_DESCRIPTION",
    profilePicture: {key: getKey(), image: ZOYA_PROFILE, alt: "ZOYA_PROFILE_ALT"},
    socialMedia: [
        {name: "zoya.ink", link: "https://www.instagram.com/zoya.ink/", icon: faSquareInstagram},
        {name: "zoya.tattoo", link: "https://www.tiktok.com/@zoya.tattoo", icon: faTiktok},
        {name: "Zoya", link: "https://www.facebook.com/profile.php?id=100082609845559", icon: faSquareFacebook},
    ],
    portfolio: portfolio
}
