import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {Artist} from "./Artists.type";
import {faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {getKey} from "../../../images/ImageKeyGenerator.service";

// @ts-ignore
import TALA_PROFILE from "../../../images/tala/tatuazystka tala.webp";
// @ts-ignore
import TALA_PORTFOLIO_1 from "../../../images/tala/tatuaz glowa .webp";
// @ts-ignore
import TALA_PORTFOLIO_2 from "../../../images/tala/tatuaz linearny skorpion.webp.webp";
// @ts-ignore
import TALA_PORTFOLIO_3 from "../../../images/tala/tatuaz lineart kot.webp";
// @ts-ignore
import TALA_PORTFOLIO_4 from "../../../images/tala/tatuaz napis.webp";
// @ts-ignore
import TALA_PORTFOLIO_5 from "../../../images/tala/tatuaz znaczek pocztowy.webp";
// @ts-ignore
import TALA_PORTFOLIO_6 from "../../../images/tala/abstrakcyjny tatuaz motyl.webp";

const portfolio: ImageProps[] = [
    {key: getKey(), image: TALA_PORTFOLIO_1, alt: "TALA_PORTFOLIO_1_ALT"},
    {key: getKey(), image: TALA_PORTFOLIO_2, alt: "TALA_PORTFOLIO_2_ALT"},
    {key: getKey(), image: TALA_PORTFOLIO_3, alt: "TALA_PORTFOLIO_3_ALT"},
    {key: getKey(), image: TALA_PORTFOLIO_4, alt: "TALA_PORTFOLIO_4_ALT"},
    {key: getKey(), image: TALA_PORTFOLIO_5, alt: "TALA_PORTFOLIO_5_ALT"},
    {key: getKey(), image: TALA_PORTFOLIO_6, alt: "TALA_PORTFOLIO_6_ALT"},
];

export const Tala: Artist = {
    name: "TALA_NAME",
    description: "TALA_DESCRIPTION",
    profilePicture: {key: getKey(), image: TALA_PROFILE, alt: "TALA_PROFILE_ALT"},
    socialMedia: [
        {name: "talinka.tattoo", link: "https://www.instagram.com/talinka.tattoo/", icon: faSquareInstagram},
    ],
    portfolio: portfolio
}
