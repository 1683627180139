import {Artist} from "./Artists.type";
import {Zoya} from "./Zoya.service";
import {Axellent} from "./Axellent.service";
import {Tala} from "./Tala.service";
import {Gabi} from "./Gabi.service";

export const getArtists = (): Artist[] => [
    Zoya,
    Axellent,
    Tala,
    Gabi
];
