exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-regulamin-kart-lojalnosciowych-tsx": () => import("./../../../src/pages/regulamin-kart-lojalnosciowych.tsx" /* webpackChunkName: "component---src-pages-regulamin-kart-lojalnosciowych-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-regulamin-voucherow-swiatecznych-tsx": () => import("./../../../src/pages/regulamin-voucherow-swiatecznych.tsx" /* webpackChunkName: "component---src-pages-regulamin-voucherow-swiatecznych-tsx" */),
  "component---src-pages-regulamin-voucherow-tsx": () => import("./../../../src/pages/regulamin-voucherow.tsx" /* webpackChunkName: "component---src-pages-regulamin-voucherow-tsx" */)
}

