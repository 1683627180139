import React, {FC} from "react";
import {StyledLoyaltyCardRegulamin} from "./LoyaltyCardRegulamin.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";

export const LoyaltyCardRegulamin: FC = () => {

    const onCopy = (e: any) => {
        e.preventDefault();
    }

    return <StyledLoyaltyCardRegulamin onCopy={(e) => onCopy(e)}>
        <Title title={"Regulamin Kart Lojalnościowych"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        <div className="c13 doc-content">
        <p className="c9"><span className="c10"></span></p>
        <p className="c6"><span className="c4">I POSTANOWIENIA OGO&#769;LNE </span></p>
        <ol className="c8 lst-kix_v3w3q46p9858-0 start" start={1}>
            <li className="c2 li-bullet-0"><span
                className="c1">Niniejszy regulamin (&bdquo;Regulamin&rdquo;) okres&#769;la zasady programu Karty Lojalno&#347;ciowej studia &bdquo;Le&#347;ny Szept Tattoo &amp; Piercing&rdquo;, jak ro&#769;wniez&#775; zasady wydawania i uz&#775;ywania Kart Lojalno&#347;ciowych.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Wydawca&#808; Kart Lojalno&#347;ciowych i organizatorem Programu jest w&#322;a&#347;ciciel studia &bdquo;Le&#347;ny Szept Tattoo &amp; Piercing&rdquo; mieszcz&#261;cy si&#281; w &nbsp;Warszawie ul. Bracka 23 lok. 46.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Karty &nbsp;Lojalno&#347;ciowe b&#281;d&#261;&#808; wydawane i akceptowane przez &nbsp;Studio.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Regulamin dost&#281;pny jest na stronie internetowej Studia oraz na instagramie.</span>
            </li>
        </ol>
        <p className="c6"><span className="c4">II WARUNKI OTRZYMANIA KARTY LOJALNO&#346;CIOWEJ </span></p>
        <ol className="c8 lst-kix_ab0sd8pj1v45-0 start" start={1}>
            <li className="c2 li-bullet-0"><span className="c1">Celem przysta&#808;pienia do Programu oraz otrzymania Karty Lojalno&#347;ciowej powinien spe&#322;nic&#769; &#322;a&#808;cznie naste&#808;puja&#808;ce warunki: </span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">a) Klient jest pe&#322;noletnia&#808; osoba&#808; fizyczna&#808;,<br/>b) Klient przynajmniej 1 raz skorzysta&#322; z dowolnej us&#322;ugi w studiu (wykona, sobie tatua&#380;, piercing, b&#261;d&#378; tooth gems)</span>
            </li>
        </ol>
        <p className="c6"><span
            className="c1">w studiu Le&#347;ny Szept Tattoo &amp; Piercing oraz wyrazi&#322; zgode&#808; na przetwarzanie danych osobowych, w celach zwia&#808;zanych z uczestnictwem w Programie.</span>
        </p>
        <p className="c6"><span className="c1">Promocje nie &#322;&#261;cz&#261; sie&#808; ze sob&#261;.</span></p>
        <p className="c6"><span className="c4">III POSTANOWIENIA SZCZEGO&#769;&#321;OWE </span></p>
        <ol className="c8 lst-kix_xgp7q9cthlhi-0 start" start={1}>
            <li className="c2 li-bullet-0"><span className="c1">Karty Lojalno&#347;ciowe be&#808;da&#808; wydawane przez wsp&oacute;&#322;pracownik&oacute;w Organizatora Klientom, kt&oacute;rzy wykonaj&#261; sobie us&#322;ugi w studiu Le&#347;ny Szept po spe&#322;nieniu warunk&oacute;w, o kt&oacute;rych mowa pkt. II.1 powy&#380;ej.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Karty Lojalno&#347;ciowe sa&#808; kartami imiennymi i upowa&#380;niaj&#261;&#808; osobe&#808; wskazana&#808; na Karcie &nbsp;do otrzymania rabatu w kwocie 50z&#322;, 100z&#322;, b&#261;d&#378; 200z&#322; na dowoln&#261; us&#322;ug&#281; w Studiu. Zni&#380;k&#281; mo&#380;na wykorzysta&#263; do 2 miesi&#281;cy po wykonaniu us&#322;ugi, na &nbsp;kt&oacute;rej wr&#281;czona zosta&#322;a karta. Zatem us&#322;uga musi zosta&#263; WYKONANA max 2 miesi&#261;ce po odbytej wizycie, je&#347;li klient zapisze sie&#808; w na us&#322;ug&#281; w okresie 2 miesi&#281;cy od wykonania pierwszej us&#322;ugi, na kt&oacute;rej wr&#281;czona zosta&#322;a mu karta, ale nie zostanie ona wykonana w tym okresie - karta traci wa&#380;no&#347;&#263;.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Podczas wizyty Klient otrzymuje dwie karty: pierwsza dla Klienta, kt&oacute;ry wykonywa&#322; sobie danego dnia &nbsp;us&#322;ug&#281;( na karcie b&#281;d&#261; znajdowa&#263; si&#281;: imi&#281;/ nazwa na social media osoby wykonuj&#261;cej us&#322;ug&#281;, imi&#281; Klienta, data do kt&oacute;rej Klient musi odby&#263; wizyt&#281;, kod), druga karta dla znajomego Klienta (na karcie b&#281;d&#261; znajdowa&#263; si&#281;: imi&#281; Klienta kt&oacute;ry wykonywa&#322; sobie danego dnia us&#322;ug&#281;, puste pole, w kt&oacute;rym Klient b&#281;dzie musia&#322; wpisa&#263; imi&#281; danej osoby, wr&#281;czaj&#261;c jej Kart&#281;, data wykorzystania, kt&oacute;ra b&#281;dzie taka sama jak w przypadku klienta, kt&oacute;ry wykona&#322; sobie danego dnia us&#322;ug&#281;, kod).</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Je&#347;li Znajomy klienta wykorzysta swoj&#261; kart&#281; - karta Klienta zyskuje dodatkowe 50z&#322; do karty Klienta, je&#347;li klient nie odby&#322; jeszcze wizyty; b&#261;d&#378; je&#347;li odby&#322; wizyt&#281; - Klient otrzymuje dodatkow&#261; kart&#281; o warto&#347;ci 50z&#322;.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Je&#347;li kilka os&oacute;b przysz&#322;o na wizyt&#281; jednego dnia - nie mog&#261; przekaza&#263; sobie p&oacute;&#378;niej Kart Lojalno&#347;ciowych do znajomego z tego samego dnia. Natomiast je&#347;li wizyty odbywaj&#261; sie w kilku innych dniach - znajomi mog&#261; przekaza&#263; sobie zni&#380;ki, nawet je&#347;li byli ju&#380; wcze&#347;niej Klientami Studia.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Karty Lojalno&#347;ciowe dla znajomych Klient mo&#380;e przekaza&#263; zar&oacute;wno osobom, kt&oacute;re nigdy nie by&#322;y klientami Studia, jak i Sta&#322;ym Klientom.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Po odbyciu trzeciej wizyty z wykorzystaniem Kart Lojalno&#347;ciowych 50z&#322;, Klient otrzymuje Kart&#281; Sta&#322;ego Klienta, kt&oacute;ra upowa&#380;nia go do otrzymywania Kart Lojalno&#347;ciowych &nbsp;-100z&#322;, zamiast -50z&#322;.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Po odbyciu 6 wizyt z Kart&#261; Sta&#322;ego Klienta - klient otrzymuje Karty Lojalno&#347;ciowe w wysoko&#347;ci 200z&#322;, zamiast. 100, je&#347;li chce - &nbsp;mo&#380;e wymieni&#263; je na dwie Karty Lojalno&#347;ciowe w wysoko&#347;ci 100z&#322;. </span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Karta Sta&#322;ego Klienta nie jest elektronicznym instrumentem p&#322;atniczym lub pienie&#808;z&#775;nym, nie jest ro&#769;wniez&#775; karta&#808; p&#322;atnicza&#808;.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Rabatu nie moz&#775;na wymienic&#769; na goto&#769;wke&#808;.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Klienta korzystaj&#261;cego z Karty Lojalno&#347;ciowej pr&oacute;cz Regulaminu Karty Lojalno&#347;ciowej, obowi&#261;zuje Regulamin Studia: https://lesnyszept.com/regulamin/</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Rabat jest udzielany wy&#322;a&#808;cznie od pierwszej ceny detalicznej wybranych us&#322;ug. Rabat nie &#322;a&#808;czy sie&#808; z innymi promocjami. Rabat nie mo&#380;e obejmowa&#263; us&#322;ug u praktykant&oacute;w studia, jednak mog&#261; oni wydawa&#263; Karty na us&#322;ugi, po wykonaniu przez nich us&#322;ugi. Zatem: po wizycie u praktykanta Klient otrzymuje od niego kart&#281;, nie mo&#380;e jednak wykorzysta&#263; jej na us&#322;ugi praktykanckie.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">W celu naliczenia Rabatu przy zapisie na us&#322;ug&#281; nalez&#775;y powiadomi&#263; pracownika Studia o fakcie jej posiadania. Za poinformowanie pracownika o fakcie wyst&#261;pienia Rabatu uwa&#380;a si&#281; napisanie tego w PIERWSZEJ WIADOMO&#346;CI przy zapisie na dan&#261; us&#322;ug&#281;, informuj&#261;c go o warto&#347;ci zni&#380;ki (na przyk&#322;ad: &bdquo;Otrzyma&#322;em kart&#281; lojalno&#347;ciow&#261; w wysoko&#347;ci 50z&#322; na us&#322;ug&#281;, chcia&#322;bym j&#261; zrealizowa&#263; zapisuj&#261;c si&#281; do Ciebie na tatua&#380;/piercing/ gemsy&rdquo;), je&#347;li powiadomienie o zni&#380;ce nast&#261;pi w p&oacute;&#378;niejszych wiadomo&#347;ciach/w Studiu - pracownik nie b&#281;dzie m&oacute;g&#322; odliczy&#263; Rabatu od us&#322;ugi.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Rabaty nie dzia&#322;aj&#261; wstecz - &nbsp;nie ma mo&#380;liwo&#347;ci zwrotu kwoty Rabatu, je&#347;li Klient wykona sobie us&#322;ug&#281;, wykorzysta swoj&#261; Kart&#281; Lojalno&#347;ciow&#261;, a jaki&#347; czas po nim wykorzysta j&#261; jego Znajomy - Klientowi nie przys&#322;uguje zwrot 50zl. Przys&#322;uguje mu natomiast wydanie kolejnej karty 50z&#322;, kt&oacute;ra mo&#380;e wykorzysta&#263; na kolejn&#261; us&#322;ug&#281;, nie p&oacute;&#378;niej ni&#380; 2 miesi&#261;ce od wykonania sobie wybranej us&#322;ugi przez Znajomego Klienta.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">W celu naliczenia Rabatu nalez&#775;y okazac&#769; pracownikowi fizycznie Karte&#808; Lojalno&#347;ciow&#261;, przed dokonaniem wyboru formy p&#322;atnos&#769;ci. Je&#347;li Klient nie b&#281;dzie mia&#322; ze sob&#261; Karty Lojalno&#347;ciowej - pracownik nie b&#281;dzie m&oacute;g&#322; naliczy&#263; Rabatu.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Pracownik nie ma obowi&#261;zku wysy&#322;a&#263; Regulaminu Kart Lojalno&#347;ciowych klientom, Regulamin jest umieszczony w formie widocznej na stronie studia i w wyr&oacute;&#380;nionych relacjach na instagramie studia. Pracownik mo&#380;e wys&#322;a&#263; Klientowi Regulamin po wcze&#347;niejszej pro&#347;bie.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">By skorzysta&#263; z Karty sta&#322;ego klienta o warto&#347;ci:</span>
            </li>
        </ol>
        <ul className="c8 lst-kix_r7k752lfvf9m-0 start">
            <li className="c2 li-bullet-0"><span
                className="c1">50z&#322;: *minimalna cena za us&#322;ug&#281; tatua&#380;u musi wynosi&#263; 300z&#322;; piercingu 120z&#322;, tooth gems 100z&#322;;</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">100z&#322;: *minimalna cena za us&#322;ug&#281; tatua&#380;u musi wynosi&#263; 400z&#322;; piercingu 220z&#322;, tooth gems 200z&#322;;</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">200z&#322;: *minimalna cena za us&#322;ug&#281; tatua&#380;u musi wynosi&#263; 800z&#322;; piercingu 500z&#322;, tooth gems 400z&#322;.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">300z&#322; (czyli maksymalana warto&#347;&#263; zni&#380;ki jak&#261; mo&#380;na osi&#261;gn&#261;&#263; przy po&#322;&#261;czeniu swojej Karty Lojalno&#347;ciowej i Kart Lojalno&#347;ciowych przekazanych znajomym): &nbsp;*minimalna cena za us&#322;ug&#281; tatua&#380;u musi wynosi&#263; 1400z&#322;; piercingu 700, tooth gems 600z&#322;.</span>
            </li>
        </ul>
        <p className="c6"><span className="c1">* &nbsp;minimalna cena za us&#322;ug&#281; to cena us&#322;ugi po odliczeniu Rabatu, kt&oacute;rej Klient nie mo&#380;e obni&#380;y&#263;, wykonuj&#261;c us&#322;ug&#281;.</span>
        </p>
        <p className="c6"><span className="c4">IV DANE OSOBOWE </span></p>
        <ol className="c8 lst-kix_4r13ipqcwsbs-0 start" start={1}>
            <li className="c2 li-bullet-0"><span className="c1">Ujawnienie przez klienta w formularzu zg&#322;oszeniowym Programu danych osobowych i wyraz&#775;enie zgody na ich przetwarzanie jest dobrowolne, jednak niezbe&#808;dne do uczestnictwa w Programie.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Administratorem danych osobowych ujawnionych na potrzeby przysta&#808;pienia do Programu jest Studio &bdquo;Le&#347;ny Szept Tattoo &amp; Piercing&rdquo;.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Dane osobowe nie be&#808;da&#808; udoste&#808;pniane odbiorcom danych w rozumieniu art. 7 pkt 6 ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2016 r., poz. 922 ze zm.) (&bdquo;Ustawa&rdquo;).</span>
            </li>
        </ol>
        <p className="c6"><span className="c4">V CZAS TRWANIA I REZYGNACJA Z PROGRAMU </span></p>
        <ol className="c8 lst-kix_sb8fov84d3q4-0 start" start={1}>
            <li className="c2 li-bullet-0"><span className="c3">Program trwa przez czas nieokres&#769;lony. Organizator moz&#775;e wstrzymac&#769; okresowo lub zaniechac&#769; przyjmowania dalszych zg&#322;oszen&#769; lub wydawania Kart Lojalno&#347;ciowych przez opublikowanie takiej decyzji na stronie internetowej: </span><span
                className="c3 c7"><a className="c0"
                                     href="https://www.google.com/url?q=https://lesnyszept.com/&amp;sa=D&amp;source=editors&amp;ust=1731598616271275&amp;usg=AOvVaw1UNluN_sBZu9j0LE-_yC_P">https://lesnyszept.com/</a></span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">&nbsp;Organizator na prawo zakon&#769;czyc&#769; niniejszy Program po uprzednim poinformowaniu umieszczenie informacji na stronie internetowej: https://lesnyszept.com/</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Klient moz&#775;e zrezygnowac&#769; z Programu poprzez z&#322;oz&#775;enie pisemnego os&#769;wiadczenia o rezygnacji w Studiu &bdquo;Le&#347;ny Szept Tattoo &amp; Piercing&rdquo;.</span>
            </li>
        </ol>
        <p className="c6"><span className="c4">VI REKLAMACJE </span></p>
        <ol className="c8 lst-kix_32v9waytk58a-0 start" start={1}>
            <li className="c2 li-bullet-0"><span className="c1">W przypadku przeprowadzenia Programu niezgodnie z Regulaminem klientowi przys&#322;uguje prawo do z&#322;oz&#775;enia reklamacji.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">W celu usprawnienia procesu zg&#322;aszania i rozpatrywania, reklamacja moz&#775;e zostac&#769; z&#322;oz&#775;ona w formie elektronicznej na adres: le&#347;ny.szept.studio@gmail.com.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Sk&#322;adaja&#808;cy reklamacje&#808; powinien uwidocznic&#769; w jej tres&#769;ci swoje dane, tak aby kontakt z nim w celu poinformowania o wyniku poste&#808;powania reklamacyjnego by&#322; moz&#775;liwy.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Rozpatrywanie reklamacji trwa 30 dni roboczych, liczonych od dnia jej otrzymania.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c1">Organizator be&#808;dzie przetwarza&#322; dane osobowe sk&#322;adaja&#808;cego reklamacje&#808; wy&#322;a&#808;cznie w zwia&#808;zku z procedura reklamacyjna&#808;, a po zakon&#769;czeniu procedury reklamacyjnej dane osobowe zostana&#808; usunie&#808;te.</span>
            </li>
        </ol>
        <p className="c6"><span className="c4">VII POSTANOWIENIA KON&#769;COWE </span></p>
        <p className="c6"><span
            className="c1">1.Organizator zastrzega sobie prawo dokonywania zmian w Regulaminie.</span></p>
        <p className="c6"><span
            className="c3">Zmieniony Regulamin udoste&#808;pniony zostanie zaro&#769;wno w Studiu &bdquo;Le&#347;ny Szept Tattoo &amp; Piercing&rdquo;, na stronie internetowej: </span><span
            className="c3 c7"><a className="c0"
                                 href="https://www.google.com/url?q=https://lesnyszept.com/&amp;sa=D&amp;source=editors&amp;ust=1731598616272302&amp;usg=AOvVaw3uK-Thsd7sJB8zhyBRYMS-">https://lesnyszept.com/</a></span><span
            className="c1">&nbsp;oraz na instagramie studia.</span></p>
        <p className="c6"><span className="c1">Zmiany be&#808;da&#808; obowia&#808;zywac&#769; od czasu publikacji zmian na stronie internetowej.</span>
        </p>
        <ol className="c8 lst-kix_vdkq1srh47ze-0 start" start={2}>
            <li className="c2 li-bullet-0"><span className="c1">Do wszelkich spraw nieuregulowanych Regulaminem zastosowanie maja&#808; przepisy powszechnie obowia&#808;zuja&#808;cego prawa.</span>
            </li>
            <li className="c2 li-bullet-0"><span
                className="c1">Regulamin wchodzi w z&#775;ycie z dniem 13.07.2024</span></li>
        </ol>
        <p className="c5"><span className="c10"></span></p></div>
    </StyledLoyaltyCardRegulamin>
};
