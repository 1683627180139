import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {Artist} from "./Artists.type";
import {faSquareInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {getKey} from "../../../images/ImageKeyGenerator.service";

// @ts-ignore
import AXELLENT_PROFILE from "../../../images/axellent/osoba tatuujaca axellent.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_1 from "../../../images/axellent/tatuaz Ash Lynx z Banana Fish.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_2 from "../../../images/axellent/tatuaz Asuka z Neon Genesis Evangelion.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_3 from "../../../images/axellent/tatuaz Czaszka wilka z dolku w lesie.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_4 from "../../../images/axellent/tatuaz Draken z Tokyo Revengers.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_5 from "../../../images/axellent/tatuaz EVA z Evangelion.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_6 from "../../../images/axellent/tatuaz Gojo Satoru z Jujutsu Kaisen.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_7 from "../../../images/axellent/tatuaz Itachi z Naruto.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_8 from "../../../images/axellent/tatuaz Kafka z Honkai Star Rail.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_9 from "../../../images/axellent/tatuaz Kaito z Vocaloid.webp";
// @ts-ignore
import AXELLENT_PORTFOLIO_10 from "../../../images/axellent/tatuaz Wriothesley z Genshin Impact.webp";

const portfolio: ImageProps[] = [
    {key: getKey(), image: AXELLENT_PORTFOLIO_1, alt: "AXELLENT_PORTFOLIO_1_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_2, alt: "AXELLENT_PORTFOLIO_2_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_3, alt: "AXELLENT_PORTFOLIO_3_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_4, alt: "AXELLENT_PORTFOLIO_4_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_5, alt: "AXELLENT_PORTFOLIO_5_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_6, alt: "AXELLENT_PORTFOLIO_6_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_7, alt: "AXELLENT_PORTFOLIO_7_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_8, alt: "AXELLENT_PORTFOLIO_8_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_9, alt: "AXELLENT_PORTFOLIO_9_ALT"},
    {key: getKey(), image: AXELLENT_PORTFOLIO_10, alt: "AXELLENT_PORTFOLIO_10_ALT"}
];

export const Axellent: Artist = {
    name: "AXELLENT_NAME",
    description: "AXELLENT_DESCRIPTION",
    profilePicture: {key: getKey(), image: AXELLENT_PROFILE, alt: "AXELLENT_PROFILE_ALT"},
    socialMedia: [
        {name: "axellent.ink", link: "https://www.instagram.com/axellent.ink/", icon: faSquareInstagram},
        {name: "axellent.ink.art", link: "https://www.instagram.com/axellent.ink.art/", icon: faSquareInstagram},
        {name: "axellent.ink", link: "https://www.tiktok.com/@axellent.ink", icon: faTiktok},
    ],
    portfolio: portfolio
}