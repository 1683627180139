import styled from "styled-components";
import {SM_MAX_SIZE} from "../../common/util/ViewSizeUtils";

export const StyledChristmasVoucherRegulamin = styled.div`&&& {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
  padding-bottom: 60px;
    .page-title-container {
        height: unset !important;    
    }
    
  .page-left-panel {
    width: calc(40vw - 160px);
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 140px);
    }

    @media only screen and (max-width: ${350}px) {
      display: none;
    }
  }

  .page-title {
    width: 320px;

    @media only screen and (max-width: ${350}px) {
      width: 100%;
    }
  }

  .page-right-panel {
    width: calc(40vw - 160px);
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 160px);
    }

    @media only screen and (max-width: ${350}px) {
      display: none;
    }
  }

    .doc-content {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: ${899}px) {
            margin-left: 30px;
            margin-right: 30px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @media only screen and (max-width: ${560}px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .lst-kix_itl0b3z6w1mz-0 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_rtzbzzfogmds-8.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-8 0
    }

    .lst-kix_itl0b3z6w1mz-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_rtzbzzfogmds-2 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-2
    }

    ol.lst-kix_rtzbzzfogmds-1.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-1 0
    }

    .lst-kix_itl0b3z6w1mz-4 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_itl0b3z6w1mz-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_itl0b3z6w1mz-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_rtzbzzfogmds-8 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-8
    }

    ol.lst-kix_rtzbzzfogmds-0 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-2 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-1 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-4 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-3 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-6 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-5 {
        list-style-type: none
    }

    .lst-kix_rtzbzzfogmds-7 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-7
    }

    ol.lst-kix_rtzbzzfogmds-8 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-7 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-4.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-4 0
    }

    .lst-kix_rtzbzzfogmds-7 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-7, lower-latin) ". "
    }

    .lst-kix_rtzbzzfogmds-6 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-6, decimal) ". "
    }

    ul.lst-kix_itl0b3z6w1mz-0 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-1 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-2 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-3 {
        list-style-type: none
    }

    .lst-kix_rtzbzzfogmds-1 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-1
    }

    ul.lst-kix_itl0b3z6w1mz-4 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-5 {
        list-style-type: none
    }

    .lst-kix_rtzbzzfogmds-8 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-8, lower-roman) ". "
    }

    ul.lst-kix_itl0b3z6w1mz-6 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-7 {
        list-style-type: none
    }

    ul.lst-kix_itl0b3z6w1mz-8 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-7.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-7 0
    }

    .lst-kix_rtzbzzfogmds-3 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-3
    }

    .lst-kix_rtzbzzfogmds-6 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-6
    }

    ol.lst-kix_rtzbzzfogmds-0.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-0 0
    }

    ol.lst-kix_rtzbzzfogmds-3.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-3 0
    }

    .lst-kix_rtzbzzfogmds-5 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-5
    }

    ol.lst-kix_rtzbzzfogmds-6.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-6 0
    }

    .lst-kix_23gmwemaue4b-8 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_rtzbzzfogmds-0 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-0
    }

    .lst-kix_23gmwemaue4b-7 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_23gmwemaue4b-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_rtzbzzfogmds-4 > li {
        counter-increment: lst-ctn-kix_rtzbzzfogmds-4
    }

    .lst-kix_23gmwemaue4b-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_23gmwemaue4b-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_23gmwemaue4b-4 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_rtzbzzfogmds-2.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-2 0
    }

    .lst-kix_23gmwemaue4b-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_rtzbzzfogmds-5 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-5, lower-roman) ". "
    }

    .lst-kix_rtzbzzfogmds-4 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-4, lower-latin) ". "
    }

    .lst-kix_23gmwemaue4b-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_rtzbzzfogmds-1 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-1, lower-latin) ". "
    }

    .lst-kix_rtzbzzfogmds-3 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-3, decimal) ". "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_rtzbzzfogmds-2 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-2, lower-roman) ". "
    }

    .lst-kix_23gmwemaue4b-0 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_itl0b3z6w1mz-8 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_itl0b3z6w1mz-7 > li:before {
        content: "\\0025cb   "
    }

    ul.lst-kix_23gmwemaue4b-0 {
        list-style-type: none
    }

    ul.lst-kix_23gmwemaue4b-1 {
        list-style-type: none
    }

    ol.lst-kix_rtzbzzfogmds-5.start {
        counter-reset: lst-ctn-kix_rtzbzzfogmds-5 0
    }

    .lst-kix_rtzbzzfogmds-0 > li:before {
        content: "" counter(lst-ctn-kix_rtzbzzfogmds-0, decimal) ") "
    }

    ul.lst-kix_23gmwemaue4b-6 {
        list-style-type: none
    }

    ul.lst-kix_23gmwemaue4b-7 {
        list-style-type: none
    }

    .lst-kix_itl0b3z6w1mz-5 > li:before {
        content: "\\0025a0   "
    }

    ul.lst-kix_23gmwemaue4b-8 {
        list-style-type: none
    }

    .lst-kix_itl0b3z6w1mz-6 > li:before {
        content: "\\0025cf   "
    }

    ul.lst-kix_23gmwemaue4b-2 {
        list-style-type: none
    }

    ul.lst-kix_23gmwemaue4b-3 {
        list-style-type: none
    }

    ul.lst-kix_23gmwemaue4b-4 {
        list-style-type: none
    }

    ul.lst-kix_23gmwemaue4b-5 {
        list-style-type: none
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c7 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #ffffff;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: center;
        padding-right: 0pt
    }

    .c1 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #ffffff;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt
    }

    .c3 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-right-width: 0pt;
        padding-left: 22.5pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #ffffff;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt
    }

    .c5 {
        color: #1b2735;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c0 {
        color: #1b2735;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c8 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

    .c6 {
        font-size: 12pt;
        font-family: "Times New Roman";
        color: #1b2735;
        font-weight: 700
    }

    .c4 {
        font-size: 12pt;
        font-family: "Times New Roman";
        color: #1b2735;
        font-weight: 400
    }

    .c11 {
    }

    .c9 {
        padding: 0;
        margin: 0
    }

    .c10 {
        background-color: #ffffff
    }

    .c2 {
        margin-left: 36pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        //padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        //padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
}`;
