import React, {FC} from "react";
import {StyledVoucherRegulamin} from "./VoucherRegulamin.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";

export const VoucherRegulamin: FC = () => {

    const onCopy = (e: any) => {
        e.preventDefault();
    }

    return <StyledVoucherRegulamin onCopy={(e) => onCopy(e)}>
        <Title title={"Regulamin Voucherów"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        <div className="c3 doc-content">
        <p className="c5"><span className="c2">I. Informacje wst&#281;pne.</span></p>
        <p className="c1"><span className="c0">Niniejszy Regulamin okre&#347;la og&oacute;lne warunki wydawania, zasady oraz spos&oacute;b korzystania z Bon&oacute;w Podarunkowych.</span>
        </p>
        <p className="c5"><span className="c8">II. Definicje.</span><span className="c0">&nbsp;</span></p>
        <p className="c1"><span className="c4">1. Bon Podarunkowy &nbsp;&ndash; bon wydany na okaziciela, sprzedawany w Studiu Le&#347;ny Szept, uprawniaj&#261;cy Posiadacza do dokonywania p&#322;atno&#347;ci za oferowane us&#322;ugi w Studiu Le&#347;ny Szept.</span>
        </p>
        <p className="c1"><span className="c0">2. Nabywca &ndash; osoba fizyczna dokonuj&#261;ca zakupu Bonu Podarunkowego w studiu Le&#347;ny Szept</span>
        </p>
        <p className="c1"><span className="c0">3. Posiadacz &ndash; osoba realizuj&#261;ca Bon Podarunkowy;</span></p>
        <p className="c1"><span className="c4">4. Regulamin &ndash; oznacza niniejszy Regulamin;</span></p>
        <p className="c1"><span
            className="c0">6. Le&#347;ny Szept &ndash; studio tatua&#380;u i piercingu, w kt&oacute;rym oferowane s&#261; do sprzeda&#380;y Us&#322;ugi i Bony Podarunkowe. Studio mie&#347;ci si&#281; przy ul. Brackiej 23/46 w Warszawie.</span>
        </p>
        <p className="c1"><span className="c4 c7">7. Us&#322;uga &ndash; us&#322;uga wykonania tatua&#380;u lub piercingu w studiu Le&#347;ny Szept;</span>
        </p>
        <p className="c1"><span className="c4">8. Wydawca &ndash; oznacza </span><span className="c10">Le&#347;ny Szept Tattoo &amp; Piercing sp. z o.o z siedzib&#261; w Warszawie, wpisan&#261; do rejestru przedsi&#281;biorc&oacute;w prowadzonego przez S&#261;d Rejonowy dla m. st. Warszawy w Warszawie, XIV Wydzia&#322; Gospodarczy Krajowego Rejestru S&#261;dowego pod nr KRS 0000987892</span>
        </p>
        <p className="c5"><span className="c2">III. Warunki og&oacute;lne.</span></p>
        <p className="c1"><span className="c0">1. Wydawca oferuje do nabycia w Studiu Le&#347;ny Szept &nbsp;Bony Podarunkowe o wybranej warto&#347;ci &nbsp;od 100 z&#322;otych (ceny zawieraj&#261; podatek VAT). Za ka&#380;dy Bon Podarunkowy Nabywca p&#322;aci odpowiedni&#261; warto&#347;&#263; nominaln&#261; Bonu Podarunkowego.</span>
        </p>
        <p className="c1"><span
            className="c0">3. Nabywca zobowi&#261;zuje si&#281; do przekazania Wydawcy &#347;rodk&oacute;w pieni&#281;&#380;nych w kwocie r&oacute;wnej warto&#347;ci nominalnej Bonu Podarunkowego.</span>
        </p>
        <p className="c1"><span className="c0">4. Wydawca zobowi&#261;zuje si&#281; do przekazania Nabywcy Bonu Podarunkowego i do przyjmowania p&#322;atno&#347;ci realizowanych przy pomocy Bonu Podarunkowego za us&#322;ugi nabywane przez Posiadacza.</span>
        </p>
        <p className="c1"><span className="c0">5. Bon Podarunkowy mo&#380;e by&#263; wykorzystany wy&#322;&#261;cznie do nabycia us&#322;ug w studiu Le&#347;ny Szept.</span>
        </p>
        <p className="c1"><span
            className="c0">6. Bon Podarunkowy nie podlega wymianie na &#347;rodki pieni&#281;&#380;ne (w ca&#322;o&#347;ci lub w cz&#281;&#347;ci) oraz nie podlega zwrotowi.</span>
        </p>
        <p className="c1"><span className="c0">7. Okres wa&#380;no&#347;ci Bonu Podarunkowego wynosi jeden rok od dnia jego sprzeda&#380;y, zgodnie z dat&#261; widniej&#261;c&#261; na dokumencie Bonu Podarunkowego. Niewykorzystanie Bonu Podarunkowego w terminie na nim wskazanym, jest r&oacute;wnoznaczne z utrat&#261; wa&#380;no&#347;ci Bonu Podarunkowego i nie stanowi podstawy do wyst&#261;pienia przez Posiadacza wobec Wydawcy z roszczeniami w tym zakresie.</span>
        </p>
        <p className="c1"><span className="c0">8. Wydanie Bonu Podarunkowego nie stanowi sprzeda&#380;y opodatkowanej podatkiem od towar&oacute;w i us&#322;ug (VAT) w rozumieniu obowi&#261;zuj&#261;cych przepis&oacute;w prawa podatkowego. Dokument sprzeda&#380;y wydawany jest w momencie nabycia us&#322;ugi.</span>
        </p>
        <p className="c5"><span
            className="c0">9. Bon Podarunkowy mo&#380;e by&#263; wykorzystany do op&#322;acenia us&#322;ugi wykonywanej przez dowoln&#261; osob&#281; pracuj&#261;c&#261; w studiu Le&#347;ny Szept.</span>
        </p>
        <p className="c5"><span
            className="c0">10. Bon Podarunkowy jest imienny i widnieje na nim imi&#281; i nazwisko Posiadacza.</span>
        </p>
        <p className="c5"><span className="c2">IV. Realizacja Bonu Podarunkowego</span></p>
        <p className="c1"><span
            className="c0">1. Posiadacz Bonu Podarunkowego ma prawo do jego realizacji na nast&#281;puj&#261;cych zasadach:</span>
        </p>
        <p className="c1"><span className="c0">a. Bon Podarunkowy stanowi form&#281; zap&#322;aty za us&#322;ugi wykonywane w Studiu Le&#347;ny Szept</span>
        </p>
        <p className="c1"><span className="c0">b. Zap&#322;ata za us&#322;ugi wymaga okazania oryginalnego Bonu Podarunkowego w dniu wykonania us&#322;ugi</span>
        </p>
        <p className="c1"><span
            className="c0">c. Posiadacz Bonu Podarunkowego mo&#380;e wykorzysta&#263; kwot&#281; wskazan&#261; na Bonie Podarunkowym na us&#322;ugi dost&#281;pne w ofercie Studia Le&#347;ny Szept dost&#281;pne na stronie internetowej: https://lesnyszept.com/pricing</span>
        </p>
        <p className="c1"><span
            className="c0">d. W przypadku zakupu us&#322;ugi o warto&#347;ci ni&#380;szej ni&#380; warto&#347;&#263; Bonu Podarunkowego, Posiadaczowi nie przys&#322;uguje prawo otrzymania zwrotu pozosta&#322;ej, niewykorzystanej kwoty;</span>
        </p>
        <p className="c1"><span
            className="c0">e. W przypadku zakupu us&#322;ugi o warto&#347;ci wy&#380;szej ni&#380; warto&#347;&#263; Bonu Podarunkowego, Posiadacz zobowi&#261;zany jest dop&#322;aci&#263; r&oacute;&#380;nic&#281;;</span>
        </p>
        <p className="c1"><span className="c0">f. Bon posiada termin wa&#380;no&#347;ci, po up&#322;ywie kt&oacute;rego traci wa&#380;no&#347;&#263;. Termin wa&#380;no&#347;ci Bonu Podarunkowego nie ulega zmianie. Po up&#322;ywie terminu wa&#380;no&#347;ci Bonu Podarunkowego, nie mo&#380;na nim dokonywa&#263; p&#322;atno&#347;ci;</span>
        </p>
        <p className="c1"><span className="c0">g. Wydawca nie odpowiada za zgubienie lub uszkodzenie Bonu Podarunkowego przez Nabywc&#281; lub Posiadacza. W takich wypadkach duplikaty nie b&#281;d&#261; wydawane;</span>
        </p>
        <p className="c1"><span className="c0">h. Wydawca zastrzega sobie prawo odmowy op&#322;acenia us&#322;ugi Bonem Podarunkowym, kt&oacute;ry jest nieczytelny, zniszczony, b&#261;d&#378; w inny spos&oacute;b nasuwaj&#261; w&#261;tpliwo&#347;ci co do ich autentyczno&#347;ci. W takim przypadku Bon Podarunkowy uznawany jest za niewa&#380;ny, bez prawa do otrzymania Bonu Podarunkowego zast&#281;pczego;</span>
        </p>
        <p className="c1"><span className="c0">i. Bon Podarunkowy traktowany jest jako zadatek i obowi&#261;zuje go regulamin studia Le&#347;ny Szept;</span>
        </p>
        <p className="c1"><span className="c4">j. Wykorzystanie Bonu Podarunkowego przez Posiadacza wymaga akceptacji regulaminu studia Le&#347;ny Szept. W przypadku braku akceptacji regulaminu studia Le&#347;ny Szept nie przys&#322;uguje prawo otrzymania zwrotu, niewykorzystanej kwoty;</span>
        </p>
        <p className="c5"><span className="c2">V. Postanowienia ko&#324;cowe</span></p>
        <p className="c1"><span className="c0">1. Nabywca jest zobowi&#261;zany do poinformowania Posiadacza o warunkach realizacji Bonu Podarunkowego i przekazaniu mu regulaminu studia Le&#347;ny Szept.</span>
        </p>
        <p className="c1"><span className="c0">2. Zakup Bonu Podarunkowego przez Nabywc&#281; oraz jego przedstawienie do realizacji przez Posiadacza jest r&oacute;wnoznaczne z akceptacj&#261; niniejszego Regulaminu przez Nabywc&#281; i Posiadacza.</span>
        </p>
        <p className="c1"><span
            className="c0">3. Tre&#347;&#263; niniejszego Regulaminu jest dost&#281;pna w studiu Le&#347;ny Szept.</span>
        </p>
        <p className="c1"><span
            className="c0">4. W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie maj&#261; przepisy Kodeksu cywilnego i innych ustaw.</span>
        </p>
        <p className="c1"><span
            className="c0">5. S&#261;dem w&#322;a&#347;ciwym do rozstrzygania spor&oacute;w mog&#261;cych wynikn&#261;&#263; na tle stosowania niniejszego Regulaminu jest s&#261;d powszechny w&#322;a&#347;ciwy dla siedziby Wydawcy.</span>
        </p>
        <p className="c1"><span className="c4">6. Wydawca zastrzega sobie prawo zmiany Regulaminu w ka&#380;dym czasie. Wszelkie zmiany obowi&#261;zuj&#261; od daty ich wprowadzenia przez Wydawc&#281;, po udost&#281;pnieniu zmienionego Regulaminu do wgl&#261;du w studiu Le&#347;ny Szept i dotyczy&#263; b&#281;d&#261; Bon&oacute;w Podarunkowego zakupionych po wej&#347;ciu w &#380;ycie zmian Regulaminu.</span>
        </p></div>
    </StyledVoucherRegulamin>
};
