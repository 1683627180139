export const getHomePageLink = (): string => {
    return "/";
}

export const getArtistsLink = (): string => {
    return "/artists";
}

export const getTattoosLink = (): string => {
    return "/tattoos";
}

export const getPiercingLink = (): string => {
    return "/piercing";
}

export const getPricingLink = (): string => {
    return "/pricing";
}

export const getBookingsLink = (): string => {
    return "/bookings";
}

export const getRegulaminLink = (): string => {
    return "/regulamin";
}

export const getChristmasRegulaminLink = (): string => {
    return "/regulamin-voucherow-swiatecznych";
}

export const getPageNotFoundLink = (): string => {
    return "/404";
}
