import styled from "styled-components";
import {SM_MAX_SIZE} from "../../common/util/ViewSizeUtils";

export const StyledLoyaltyCardRegulamin = styled.div`&&& {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* Standard */
    padding-bottom: 60px;

    .page-title-container {
        height: unset !important;
    }

    .page-left-panel {
        width: calc(40vw - 210px);
        @media only screen and (max-width: ${SM_MAX_SIZE}px) {
            width: calc(50vw - 140px);
        }

        @media only screen and (max-width: ${530}px) {
            display: none;
        }
    }

    .page-title {
        width: 420px;

        @media only screen and (max-width: ${530}px) {
            width: 100%;
        }
        
        @media only screen and (max-width: ${420}px) {
            overflow-wrap: break-word;
        }
    }

    .page-right-panel {
        width: calc(40vw - 210px);
        @media only screen and (max-width: ${SM_MAX_SIZE}px) {
            width: calc(50vw - 160px);
        }

        @media only screen and (max-width: ${530}px) {
            display: none;
        }
    }

    .doc-content {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: ${899}px) {
            margin-left: 30px;
            margin-right: 30px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @media only screen and (max-width: ${560}px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

    ul.lst-kix_r7k752lfvf9m-3 {
        list-style-type: none
    }

    ul.lst-kix_r7k752lfvf9m-4 {
        list-style-type: none
    }

    ul.lst-kix_r7k752lfvf9m-1 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-1.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-1 0
    }

    ul.lst-kix_r7k752lfvf9m-2 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-7 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-7, lower-latin) ". "
    }

    ol.lst-kix_sb8fov84d3q4-5 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-5 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-5, lower-roman) ". "
    }

    ul.lst-kix_r7k752lfvf9m-0 {
        list-style-type: none
    }

    .lst-kix_sb8fov84d3q4-3 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-3
    }

    ol.lst-kix_sb8fov84d3q4-6 {
        list-style-type: none
    }

    .lst-kix_v3w3q46p9858-3 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-3
    }

    ol.lst-kix_sb8fov84d3q4-7 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-4 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-4, lower-latin) ". "
    }

    ol.lst-kix_xgp7q9cthlhi-2.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-2 0
    }

    ol.lst-kix_sb8fov84d3q4-8 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-8 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-8, lower-roman) ". "
    }

    .lst-kix_32v9waytk58a-3 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-3, decimal) ". "
    }

    .lst-kix_u1wbsiyfoxmd-5 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-5
    }

    ol.lst-kix_vdkq1srh47ze-6.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-6 0
    }

    .lst-kix_32v9waytk58a-2 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-2, lower-roman) ". "
    }

    ol.lst-kix_ab0sd8pj1v45-5.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-5 0
    }

    ol.lst-kix_u1wbsiyfoxmd-1.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-1 0
    }

    ol.lst-kix_4r13ipqcwsbs-7.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-7 0
    }

    ol.lst-kix_v3w3q46p9858-1.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-1 0
    }

    .lst-kix_vdkq1srh47ze-6 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-6
    }

    ol.lst-kix_sb8fov84d3q4-1 {
        list-style-type: none
    }

    .lst-kix_xgp7q9cthlhi-0 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-0
    }

    ol.lst-kix_sb8fov84d3q4-2 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-3 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-4 {
        list-style-type: none
    }

    ul.lst-kix_r7k752lfvf9m-7 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-0.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-0 1
    }

    ul.lst-kix_r7k752lfvf9m-8 {
        list-style-type: none
    }

    ul.lst-kix_r7k752lfvf9m-5 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-6 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-6, decimal) ". "
    }

    ul.lst-kix_r7k752lfvf9m-6 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-0 {
        list-style-type: none
    }

    .lst-kix_vdkq1srh47ze-5 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-5, lower-roman) ". "
    }

    ol.lst-kix_sb8fov84d3q4-7.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-7 0
    }

    .lst-kix_vdkq1srh47ze-4 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-4, lower-latin) ". "
    }

    .lst-kix_vdkq1srh47ze-6 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-6, decimal) ". "
    }

    ol.lst-kix_xgp7q9cthlhi-8.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-8 0
    }

    .lst-kix_vdkq1srh47ze-3 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-3, decimal) ". "
    }

    .lst-kix_vdkq1srh47ze-7 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-7, lower-latin) ". "
    }

    .lst-kix_xgp7q9cthlhi-2 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-2
    }

    .lst-kix_vdkq1srh47ze-1 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-1, lower-latin) ". "
    }

    .lst-kix_vdkq1srh47ze-0 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-0, decimal) ". "
    }

    .lst-kix_vdkq1srh47ze-2 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-2, lower-roman) ". "
    }

    .lst-kix_vdkq1srh47ze-8 > li:before {
        content: "" counter(lst-ctn-kix_vdkq1srh47ze-8, lower-roman) ". "
    }

    ul.lst-kix_fmj0ktgitjhp-8 {
        list-style-type: none
    }

    .lst-kix_sb8fov84d3q4-1 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-1
    }

    ul.lst-kix_fmj0ktgitjhp-7 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-1.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-1 0
    }

    ol.lst-kix_32v9waytk58a-4.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-4 0
    }

    ul.lst-kix_fmj0ktgitjhp-4 {
        list-style-type: none
    }

    ul.lst-kix_fmj0ktgitjhp-3 {
        list-style-type: none
    }

    ul.lst-kix_fmj0ktgitjhp-6 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-1 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-1
    }

    ul.lst-kix_fmj0ktgitjhp-5 {
        list-style-type: none
    }

    ul.lst-kix_fmj0ktgitjhp-0 {
        list-style-type: none
    }

    .lst-kix_v3w3q46p9858-5 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-5
    }

    ul.lst-kix_fmj0ktgitjhp-2 {
        list-style-type: none
    }

    .lst-kix_u1wbsiyfoxmd-3 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-3
    }

    ul.lst-kix_fmj0ktgitjhp-1 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-5 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-5
    }

    .lst-kix_u1wbsiyfoxmd-8 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-8, lower-roman) ". "
    }

    ol.lst-kix_vdkq1srh47ze-1.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-1 0
    }

    ol.lst-kix_xgp7q9cthlhi-7.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-7 0
    }

    ul.lst-kix_2dd1k28fpq6y-2 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-0 {
        list-style-type: none
    }

    ul.lst-kix_2dd1k28fpq6y-1 {
        list-style-type: none
    }

    ul.lst-kix_2dd1k28fpq6y-4 {
        list-style-type: none
    }

    .lst-kix_4r13ipqcwsbs-7 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-7
    }

    ul.lst-kix_2dd1k28fpq6y-3 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-3 {
        list-style-type: none
    }

    ul.lst-kix_2dd1k28fpq6y-6 {
        list-style-type: none
    }

    .lst-kix_v3w3q46p9858-7 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-7
    }

    ol.lst-kix_32v9waytk58a-4 {
        list-style-type: none
    }

    ul.lst-kix_2dd1k28fpq6y-5 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-1 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-4.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-4 0
    }

    ul.lst-kix_2dd1k28fpq6y-8 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-2 {
        list-style-type: none
    }

    ul.lst-kix_2dd1k28fpq6y-7 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-3.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-3 0
    }

    ol.lst-kix_32v9waytk58a-7 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-8 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-5 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-6 {
        list-style-type: none
    }

    .lst-kix_4r13ipqcwsbs-3 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-3
    }

    ol.lst-kix_xgp7q9cthlhi-1.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-1 0
    }

    ol.lst-kix_v3w3q46p9858-6.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-6 0
    }

    ul.lst-kix_2dd1k28fpq6y-0 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-0 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-2 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-1 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-4 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-3 {
        list-style-type: none
    }

    .lst-kix_sb8fov84d3q4-5 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-5
    }

    ol.lst-kix_vdkq1srh47ze-6 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-2.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-2 0
    }

    ol.lst-kix_vdkq1srh47ze-5 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-7.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-7 0
    }

    ol.lst-kix_vdkq1srh47ze-8 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-7 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-0.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-0 0
    }

    .lst-kix_ab0sd8pj1v45-1 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-1
    }

    ol.lst-kix_v3w3q46p9858-7.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-7 0
    }

    .lst-kix_v3w3q46p9858-1 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-1
    }

    .lst-kix_32v9waytk58a-1 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-1, lower-latin) ". "
    }

    ol.lst-kix_v3w3q46p9858-0.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-0 0
    }

    .lst-kix_32v9waytk58a-0 > li:before {
        content: "" counter(lst-ctn-kix_32v9waytk58a-0, decimal) ". "
    }

    .lst-kix_2dd1k28fpq6y-0 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_vdkq1srh47ze-4 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-4
    }

    ol.lst-kix_4r13ipqcwsbs-6.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-6 0
    }

    ol.lst-kix_v3w3q46p9858-3 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-7 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-7, lower-latin) ". "
    }

    ol.lst-kix_v3w3q46p9858-4 {
        list-style-type: none
    }

    ol.lst-kix_v3w3q46p9858-5 {
        list-style-type: none
    }

    ol.lst-kix_v3w3q46p9858-6 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-5 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-5, lower-roman) ". "
    }

    ol.lst-kix_v3w3q46p9858-0 {
        list-style-type: none
    }

    .lst-kix_2dd1k28fpq6y-4 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_2dd1k28fpq6y-8 > li:before {
        content: "\\0025a0   "
    }

    ol.lst-kix_v3w3q46p9858-1 {
        list-style-type: none
    }

    ol.lst-kix_v3w3q46p9858-2 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-3 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-3, decimal) ". "
    }

    ol.lst-kix_sb8fov84d3q4-8.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-8 0
    }

    ol.lst-kix_u1wbsiyfoxmd-8.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-8 0
    }

    ol.lst-kix_v3w3q46p9858-7 {
        list-style-type: none
    }

    ol.lst-kix_v3w3q46p9858-8 {
        list-style-type: none
    }

    .lst-kix_r7k752lfvf9m-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_2dd1k28fpq6y-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_r7k752lfvf9m-7 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_xgp7q9cthlhi-6.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-6 0
    }

    .lst-kix_ab0sd8pj1v45-5 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-5
    }

    .lst-kix_2dd1k28fpq6y-6 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_sb8fov84d3q4-5.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-5 0
    }

    ol.lst-kix_ab0sd8pj1v45-0 {
        list-style-type: none
    }

    .lst-kix_v3w3q46p9858-3 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-3, decimal) ". "
    }

    ol.lst-kix_ab0sd8pj1v45-2 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-1 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-4 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-3 {
        list-style-type: none
    }

    .lst-kix_xgp7q9cthlhi-5 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-5
    }

    ol.lst-kix_ab0sd8pj1v45-6 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-0.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-0 0
    }

    ol.lst-kix_ab0sd8pj1v45-5 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-8 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-7 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-8.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-8 0
    }

    .lst-kix_ab0sd8pj1v45-6 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-6
    }

    .lst-kix_v3w3q46p9858-1 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-1, lower-latin) ". "
    }

    ol.lst-kix_v3w3q46p9858-8.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-8 0
    }

    .lst-kix_4r13ipqcwsbs-1 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-1
    }

    .lst-kix_sb8fov84d3q4-7 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-7
    }

    .lst-kix_xgp7q9cthlhi-1 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-1, lower-latin) ". "
    }

    .lst-kix_xgp7q9cthlhi-5 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-5, lower-roman) ". "
    }

    .lst-kix_u1wbsiyfoxmd-0 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-0
    }

    .lst-kix_fmj0ktgitjhp-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_v3w3q46p9858-7 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-7, lower-latin) ". "
    }

    .lst-kix_v3w3q46p9858-8 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-8
    }

    .lst-kix_4r13ipqcwsbs-5 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-5, lower-roman) ". "
    }

    .lst-kix_4r13ipqcwsbs-7 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-7, lower-latin) ". "
    }

    .lst-kix_u1wbsiyfoxmd-7 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-7, lower-latin) ". "
    }

    ol.lst-kix_u1wbsiyfoxmd-5.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-5 0
    }

    .lst-kix_32v9waytk58a-7 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-7
    }

    .lst-kix_v3w3q46p9858-5 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-5, lower-roman) ". "
    }

    .lst-kix_xgp7q9cthlhi-3 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-3, decimal) ". "
    }

    .lst-kix_fmj0ktgitjhp-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_vdkq1srh47ze-8 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-8
    }

    .lst-kix_4r13ipqcwsbs-1 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-1, lower-latin) ". "
    }

    .lst-kix_4r13ipqcwsbs-3 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-3, decimal) ". "
    }

    ol.lst-kix_sb8fov84d3q4-6.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-6 0
    }

    .lst-kix_sb8fov84d3q4-0 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-0
    }

    .lst-kix_vdkq1srh47ze-2 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-2
    }

    .lst-kix_4r13ipqcwsbs-2 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-2
    }

    .lst-kix_u1wbsiyfoxmd-5 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-5, lower-roman) ". "
    }

    .lst-kix_fmj0ktgitjhp-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_u1wbsiyfoxmd-3 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-3, decimal) ". "
    }

    .lst-kix_u1wbsiyfoxmd-1 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-1, lower-latin) ". "
    }

    .lst-kix_vdkq1srh47ze-3 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-3
    }

    ol.lst-kix_xgp7q9cthlhi-4.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-4 0
    }

    ol.lst-kix_u1wbsiyfoxmd-6.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-6 0
    }

    .lst-kix_fmj0ktgitjhp-7 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_sb8fov84d3q4-6 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-6
    }

    ol.lst-kix_ab0sd8pj1v45-7.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-7 0
    }

    ol.lst-kix_ab0sd8pj1v45-6.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-6 0
    }

    .lst-kix_u1wbsiyfoxmd-7 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-7
    }

    .lst-kix_u1wbsiyfoxmd-1 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-1
    }

    .lst-kix_32v9waytk58a-6 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-6
    }

    .lst-kix_32v9waytk58a-0 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-0
    }

    ol.lst-kix_xgp7q9cthlhi-3.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-3 0
    }

    .lst-kix_xgp7q9cthlhi-4 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-4
    }

    ol.lst-kix_u1wbsiyfoxmd-7.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-7 0
    }

    .lst-kix_r7k752lfvf9m-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_r7k752lfvf9m-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_4r13ipqcwsbs-8 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-8
    }

    .lst-kix_32v9waytk58a-2 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-2
    }

    .lst-kix_sb8fov84d3q4-1 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-1, lower-latin) ". "
    }

    .lst-kix_sb8fov84d3q4-3 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-3, decimal) ". "
    }

    ol.lst-kix_v3w3q46p9858-4.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-4 0
    }

    ol.lst-kix_vdkq1srh47ze-3.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-3 0
    }

    .lst-kix_sb8fov84d3q4-2 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-2, lower-roman) ". "
    }

    .lst-kix_sb8fov84d3q4-6 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-6, decimal) ". "
    }

    .lst-kix_sb8fov84d3q4-5 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-5, lower-roman) ". "
    }

    .lst-kix_vdkq1srh47ze-5 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-5
    }

    .lst-kix_4r13ipqcwsbs-4 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-4
    }

    ol.lst-kix_32v9waytk58a-1.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-1 0
    }

    ol.lst-kix_4r13ipqcwsbs-4.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-4 0
    }

    .lst-kix_sb8fov84d3q4-4 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-4, lower-latin) ". "
    }

    ol.lst-kix_ab0sd8pj1v45-2.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-2 0
    }

    .lst-kix_sb8fov84d3q4-7 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-7, lower-latin) ". "
    }

    .lst-kix_sb8fov84d3q4-8 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-8, lower-roman) ". "
    }

    ol.lst-kix_xgp7q9cthlhi-4 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-3 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-6 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-5 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-8 {
        list-style-type: none
    }

    ol.lst-kix_32v9waytk58a-7.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-7 0
    }

    ol.lst-kix_xgp7q9cthlhi-7 {
        list-style-type: none
    }

    .lst-kix_4r13ipqcwsbs-6 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-6
    }

    ul.lst-kix_mry4le4zxi36-7 {
        list-style-type: none
    }

    ul.lst-kix_mry4le4zxi36-6 {
        list-style-type: none
    }

    ul.lst-kix_mry4le4zxi36-8 {
        list-style-type: none
    }

    ol.lst-kix_ab0sd8pj1v45-8.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-8 0
    }

    .lst-kix_sb8fov84d3q4-4 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-4
    }

    ol.lst-kix_sb8fov84d3q4-4.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-4 0
    }

    .lst-kix_v3w3q46p9858-2 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-2
    }

    ul.lst-kix_mry4le4zxi36-1 {
        list-style-type: none
    }

    ul.lst-kix_mry4le4zxi36-0 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-0 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-0
    }

    ul.lst-kix_mry4le4zxi36-3 {
        list-style-type: none
    }

    .lst-kix_u1wbsiyfoxmd-6 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-6
    }

    ol.lst-kix_xgp7q9cthlhi-0 {
        list-style-type: none
    }

    ul.lst-kix_mry4le4zxi36-2 {
        list-style-type: none
    }

    .lst-kix_32v9waytk58a-4 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-4
    }

    ul.lst-kix_mry4le4zxi36-5 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-2 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-5.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-5 0
    }

    ul.lst-kix_mry4le4zxi36-4 {
        list-style-type: none
    }

    ol.lst-kix_xgp7q9cthlhi-1 {
        list-style-type: none
    }

    ol.lst-kix_vdkq1srh47ze-8.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-8 0
    }

    ol.lst-kix_u1wbsiyfoxmd-0 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-2 {
        list-style-type: none
    }

    .lst-kix_xgp7q9cthlhi-8 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-8, lower-roman) ". "
    }

    ol.lst-kix_u1wbsiyfoxmd-1 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-4 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-3 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-6 {
        list-style-type: none
    }

    .lst-kix_xgp7q9cthlhi-6 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-6, decimal) ". "
    }

    ol.lst-kix_u1wbsiyfoxmd-5 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-8 {
        list-style-type: none
    }

    ol.lst-kix_u1wbsiyfoxmd-7 {
        list-style-type: none
    }

    ol.lst-kix_sb8fov84d3q4-3.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-3 0
    }

    ol.lst-kix_u1wbsiyfoxmd-3.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-3 0
    }

    .lst-kix_xgp7q9cthlhi-7 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-7, lower-latin) ". "
    }

    .lst-kix_u1wbsiyfoxmd-2 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-2
    }

    .lst-kix_mry4le4zxi36-0 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_mry4le4zxi36-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_mry4le4zxi36-4 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_u1wbsiyfoxmd-8 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-8
    }

    .lst-kix_xgp7q9cthlhi-3 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-3
    }

    .lst-kix_mry4le4zxi36-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_mry4le4zxi36-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_mry4le4zxi36-8 > li:before {
        content: "\\0025a0   "
    }

    ol.lst-kix_u1wbsiyfoxmd-4.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-4 0
    }

    .lst-kix_mry4le4zxi36-7 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_32v9waytk58a-2.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-2 0
    }

    .lst-kix_sb8fov84d3q4-2 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-2
    }

    .lst-kix_mry4le4zxi36-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_mry4le4zxi36-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_v3w3q46p9858-4 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-4
    }

    .lst-kix_ab0sd8pj1v45-0 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-0, decimal) ". "
    }

    ol.lst-kix_ab0sd8pj1v45-3.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-3 0
    }

    .lst-kix_ab0sd8pj1v45-4 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-4
    }

    .lst-kix_ab0sd8pj1v45-1 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-1, lower-latin) ". "
    }

    .lst-kix_ab0sd8pj1v45-2 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-2, lower-roman) ". "
    }

    .lst-kix_sb8fov84d3q4-8 > li {
        counter-increment: lst-ctn-kix_sb8fov84d3q4-8
    }

    .lst-kix_ab0sd8pj1v45-7 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-7
    }

    .lst-kix_vdkq1srh47ze-7 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-7
    }

    ol.lst-kix_xgp7q9cthlhi-0.start {
        counter-reset: lst-ctn-kix_xgp7q9cthlhi-0 0
    }

    .lst-kix_vdkq1srh47ze-1 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-1
    }

    .lst-kix_sb8fov84d3q4-0 > li:before {
        content: "" counter(lst-ctn-kix_sb8fov84d3q4-0, decimal) ". "
    }

    ol.lst-kix_4r13ipqcwsbs-6 {
        list-style-type: none
    }

    .lst-kix_2dd1k28fpq6y-5 > li:before {
        content: "\\0025a0   "
    }

    ol.lst-kix_4r13ipqcwsbs-5 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-8 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-8, lower-roman) ". "
    }

    ol.lst-kix_4r13ipqcwsbs-8 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-7 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-2 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-1 {
        list-style-type: none
    }

    .lst-kix_ab0sd8pj1v45-6 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-6, decimal) ". "
    }

    .lst-kix_xgp7q9cthlhi-6 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-6
    }

    ol.lst-kix_4r13ipqcwsbs-4 {
        list-style-type: none
    }

    ol.lst-kix_4r13ipqcwsbs-3 {
        list-style-type: none
    }

    .lst-kix_2dd1k28fpq6y-1 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_ab0sd8pj1v45-4 > li:before {
        content: "" counter(lst-ctn-kix_ab0sd8pj1v45-4, lower-latin) ". "
    }

    .lst-kix_r7k752lfvf9m-4 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_2dd1k28fpq6y-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_32v9waytk58a-8 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-8
    }

    .lst-kix_r7k752lfvf9m-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_vdkq1srh47ze-0 > li {
        counter-increment: lst-ctn-kix_vdkq1srh47ze-0
    }

    .lst-kix_xgp7q9cthlhi-7 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-7
    }

    ol.lst-kix_32v9waytk58a-5.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-5 0
    }

    .lst-kix_r7k752lfvf9m-8 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_2dd1k28fpq6y-7 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_4r13ipqcwsbs-0 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-0
    }

    ol.lst-kix_32v9waytk58a-8.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-8 0
    }

    ol.lst-kix_vdkq1srh47ze-2.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-2 0
    }

    .lst-kix_v3w3q46p9858-4 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-4, lower-latin) ". "
    }

    ol.lst-kix_4r13ipqcwsbs-5.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-5 0
    }

    .lst-kix_ab0sd8pj1v45-3 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-3
    }

    .lst-kix_v3w3q46p9858-0 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-0, decimal) ". "
    }

    .lst-kix_v3w3q46p9858-8 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-8, lower-roman) ". "
    }

    ol.lst-kix_ab0sd8pj1v45-1.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-1 0
    }

    .lst-kix_v3w3q46p9858-2 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-2, lower-roman) ". "
    }

    .lst-kix_xgp7q9cthlhi-2 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-2, lower-roman) ". "
    }

    .lst-kix_xgp7q9cthlhi-0 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-0, decimal) ". "
    }

    .lst-kix_xgp7q9cthlhi-4 > li:before {
        content: "" counter(lst-ctn-kix_xgp7q9cthlhi-4, lower-latin) ". "
    }

    .lst-kix_4r13ipqcwsbs-8 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-8, lower-roman) ". "
    }

    ol.lst-kix_u1wbsiyfoxmd-2.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-2 0
    }

    .lst-kix_xgp7q9cthlhi-8 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-8
    }

    ol.lst-kix_4r13ipqcwsbs-0 {
        list-style-type: none
    }

    .lst-kix_u1wbsiyfoxmd-6 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-6, decimal) ". "
    }

    ol.lst-kix_v3w3q46p9858-2.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-2 0
    }

    ol.lst-kix_v3w3q46p9858-5.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-5 0
    }

    .lst-kix_fmj0ktgitjhp-0 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_v3w3q46p9858-6 > li:before {
        content: "" counter(lst-ctn-kix_v3w3q46p9858-6, decimal) ". "
    }

    .lst-kix_4r13ipqcwsbs-6 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-6, decimal) ". "
    }

    .lst-kix_v3w3q46p9858-6 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-6
    }

    .lst-kix_fmj0ktgitjhp-4 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_fmj0ktgitjhp-6 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_ab0sd8pj1v45-0.start {
        counter-reset: lst-ctn-kix_ab0sd8pj1v45-0 0
    }

    .lst-kix_4r13ipqcwsbs-0 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-0, decimal) ". "
    }

    .lst-kix_4r13ipqcwsbs-4 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-4, lower-latin) ". "
    }

    ol.lst-kix_32v9waytk58a-6.start {
        counter-reset: lst-ctn-kix_32v9waytk58a-6 0
    }

    .lst-kix_fmj0ktgitjhp-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_u1wbsiyfoxmd-4 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-4, lower-latin) ". "
    }

    .lst-kix_4r13ipqcwsbs-2 > li:before {
        content: "" counter(lst-ctn-kix_4r13ipqcwsbs-2, lower-roman) ". "
    }

    .lst-kix_u1wbsiyfoxmd-0 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-0, decimal) ". "
    }

    .lst-kix_u1wbsiyfoxmd-2 > li:before {
        content: "" counter(lst-ctn-kix_u1wbsiyfoxmd-2, lower-roman) ". "
    }

    ol.lst-kix_vdkq1srh47ze-5.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-5 0
    }

    .lst-kix_ab0sd8pj1v45-8 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-8
    }

    ol.lst-kix_4r13ipqcwsbs-2.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-2 0
    }

    .lst-kix_fmj0ktgitjhp-8 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_ab0sd8pj1v45-2 > li {
        counter-increment: lst-ctn-kix_ab0sd8pj1v45-2
    }

    .lst-kix_v3w3q46p9858-0 > li {
        counter-increment: lst-ctn-kix_v3w3q46p9858-0
    }

    .lst-kix_u1wbsiyfoxmd-4 > li {
        counter-increment: lst-ctn-kix_u1wbsiyfoxmd-4
    }

    ol.lst-kix_4r13ipqcwsbs-3.start {
        counter-reset: lst-ctn-kix_4r13ipqcwsbs-3 0
    }

    ol.lst-kix_sb8fov84d3q4-0.start {
        counter-reset: lst-ctn-kix_sb8fov84d3q4-0 0
    }

    ol.lst-kix_v3w3q46p9858-3.start {
        counter-reset: lst-ctn-kix_v3w3q46p9858-3 0
    }

    .lst-kix_32v9waytk58a-3 > li {
        counter-increment: lst-ctn-kix_32v9waytk58a-3
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol.lst-kix_u1wbsiyfoxmd-0.start {
        counter-reset: lst-ctn-kix_u1wbsiyfoxmd-0 1
    }

    .lst-kix_xgp7q9cthlhi-1 > li {
        counter-increment: lst-ctn-kix_xgp7q9cthlhi-1
    }

    .lst-kix_r7k752lfvf9m-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_4r13ipqcwsbs-5 > li {
        counter-increment: lst-ctn-kix_4r13ipqcwsbs-5
    }

    .lst-kix_r7k752lfvf9m-0 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_vdkq1srh47ze-4.start {
        counter-reset: lst-ctn-kix_vdkq1srh47ze-4 0
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c2 {
        padding-top: 1.5pt;
        border-top-width: 0pt;
        padding-left: 0pt;
        padding-bottom: 1.5pt;
        line-height: 1.15;
        border-top-style: solid;
        margin-left: 36pt;
        border-bottom-width: 0pt;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c10 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 11pt
    }

    .c4 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

    .c11 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 16pt;
        font-family: "Calibri";
        font-style: normal
    }

    .c12 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    .c6 {
        padding-top: 0pt;
        padding-bottom: 15pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c13 {
        background-color: #ffffff;
    }

    .c3 {
        font-size: 10.5pt;
        font-family: "Calibri";
        font-weight: 400
    }

    .c7 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c0 {
        color: inherit;
        text-decoration: inherit
    }

    .c8 {
        padding: 0;
        margin: 0
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
}`;
