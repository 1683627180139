import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {Artist} from "./Artists.type";
import {getKey} from "../../../images/ImageKeyGenerator.service";

// @ts-ignore
import CYZANA_PROFILE from "../../../images/cyzana/tatuatorka cyzana.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_2 from "../../../images/cyzana/tatuaz chomika.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_3 from "../../../images/cyzana/tatuaz karpi.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_4 from "../../../images/cyzana/tatuaz kota.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_5 from "../../../images/cyzana/tatuaz kozy.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_6 from "../../../images/cyzana/tatuaz misia.webp";
// @ts-ignore
import CYZANA_PORTFOLIO_7 from "../../../images/cyzana/tatuaz swieczki.webp";

const portfolio: ImageProps[] = [
    {key: getKey(), image: CYZANA_PORTFOLIO_2, alt: "CYZANA_PORTFOLIO_2_ALT"},
    {key: getKey(), image: CYZANA_PORTFOLIO_3, alt: "CYZANA_PORTFOLIO_3_ALT"},
    {key: getKey(), image: CYZANA_PORTFOLIO_4, alt: "CYZANA_PORTFOLIO_4_ALT"},
    {key: getKey(), image: CYZANA_PORTFOLIO_5, alt: "CYZANA_PORTFOLIO_5_ALT"},
    {key: getKey(), image: CYZANA_PORTFOLIO_6, alt: "CYZANA_PORTFOLIO_6_ALT"},
    {key: getKey(), image: CYZANA_PORTFOLIO_7, alt: "CYZANA_PORTFOLIO_7_ALT"}
];

export const Cyzana: Artist = {
    name: "CYZANA_NAME",
    description: "CYZANA_DESCRIPTION",
    profilePicture: {key: getKey(), image: CYZANA_PROFILE, alt: "CYZANA_PROFILE_ALT"},
    socialMedia: [
        {name: "cyzana.tattoo", link: "https://www.instagram.com/cyzana.tattoo/", icon: faSquareInstagram},
    ],
    portfolio: portfolio
}
