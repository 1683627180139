import {Artist} from "./Artists.type";
import {faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";

// @ts-ignore
import SAMOLECZENIE_PROFILE from "../../../images/samoleczenie/tatuazysta samoleczenie.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_1 from "../../../images/samoleczenie/abstrakcyjny tatuaz oczu.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_2 from "../../../images/samoleczenie/tatuaz kobiety ze skrzydlami wazki z glowy.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_3 from "../../../images/samoleczenie/tatuaz nozyczki z motylem.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_4 from "../../../images/samoleczenie/tatuaz nozyczki z okiem.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_5 from "../../../images/samoleczenie/tatuaz obrazu beksinskiego 2.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_6 from "../../../images/samoleczenie/tatuaz obrazu beksinskiego.webp";
// @ts-ignore
import SAMOLECZENIE_PRORTFOLIO_7 from "../../../images/samoleczenie/tatuaz pocaunku.webp";

import {getKey} from "../../../images/ImageKeyGenerator.service";

const portfolio: ImageProps[] = [
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_1, alt: "SAMOLECZENIE_PRORTFOLIO_1_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_2, alt: "SAMOLECZENIE_PRORTFOLIO_2_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_3, alt: "SAMOLECZENIE_PRORTFOLIO_3_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_4, alt: "SAMOLECZENIE_PRORTFOLIO_4_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_5, alt: "SAMOLECZENIE_PRORTFOLIO_5_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_6, alt: "SAMOLECZENIE_PRORTFOLIO_6_ALT"},
    {key: getKey(), image: SAMOLECZENIE_PRORTFOLIO_7, alt: "SAMOLECZENIE_PRORTFOLIO_7_ALT"},
];

export const Samoleczenie: Artist = {
    name: "SAMOLECZENIE_NAME",
    description: "SAMOLECZENIE_DESCRIPTION",
    profilePicture: {key: getKey(), image: SAMOLECZENIE_PROFILE, alt: "SAMOLECZENIE_PROFILE_ALT"},
    socialMedia: [
        {name: "samoleczenie", link: "https://www.instagram.com/samoleczenie/", icon: faSquareInstagram},
    ],
    portfolio: portfolio
}
