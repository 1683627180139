import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {Artist} from "./Artists.type";
import {faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {getKey} from "../../../images/ImageKeyGenerator.service";

// @ts-ignore
import GABI_PROFILE from "../../../images/gabi/piercerka gabi.webp";
// @ts-ignore
import GABI_PORTFOLIO_1 from "../../../images/gabi/kolczyk bridge i septum.webp";
// @ts-ignore
import GABI_PORTFOLIO_2 from "../../../images/gabi/kolczyk conch i rook.webp";
// @ts-ignore
import GABI_PORTFOLIO_3 from "../../../images/gabi/kolczyk daith i upper lobe.webp";
// @ts-ignore
import GABI_PORTFOLIO_4 from "../../../images/gabi/kolczyk helix i conch.webp";
// @ts-ignore
import GABI_PORTFOLIO_5 from "../../../images/gabi/kolczyk septum i labret.webp";
// @ts-ignore
import GABI_PORTFOLIO_6 from "../../../images/gabi/kolczyk septum i vertical labret.webp";
// @ts-ignore
import GABI_PORTFOLIO_7 from "../../../images/gabi/kolczyk upper lobe i forward helix.webp";
// @ts-ignore
import GABI_PORTFOLIO_8 from "../../../images/gabi/przeklocie jezyka.webp";

const portfolio: ImageProps[] = [
    {key: getKey(), image: GABI_PORTFOLIO_1, alt: "GABI_PORTFOLIO_1_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_2, alt: "GABI_PORTFOLIO_2_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_3, alt: "GABI_PORTFOLIO_3_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_4, alt: "GABI_PORTFOLIO_4_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_5, alt: "GABI_PORTFOLIO_5_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_6, alt: "GABI_PORTFOLIO_6_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_7, alt: "GABI_PORTFOLIO_7_ALT"},
    {key: getKey(), image: GABI_PORTFOLIO_8, alt: "GABI_PORTFOLIO_8_ALT"}
];

export const Gabi: Artist = {
    name: "GABI_NAME",
    description: "GABI_DESCRIPTION",
    profilePicture: {key: getKey(), image: GABI_PROFILE, alt: "GABI_PROFILE_ALT"},
    socialMedia: [
        {name: "crimsonkuje", link: "https://www.instagram.com/crimsonkuje/", icon: faSquareInstagram},
    ],
    portfolio: portfolio
}
